import MarketplaceModel from "../models/marketplaceModel";

const getAllMarketplaces = async () => {
	const res = await MarketplaceModel
	.where({status: 'published'})
	.extraParams({ paginate: false})
	.order({ position: 'asc' })
	.all();

	return res.data;
};

const getMarketplaces = async (status, page, city = null) => {
	const WHERE = (city && city !== 'all') ? {
		status: status,
		cityId: city
	} : {
		status: status
	}

	const res = await MarketplaceModel
	.where(WHERE)
	.includes(['owner', 'city', { marketplaceTags: 'tag' }])
	.page(page)
	.per(15)
	.stats({ total: 'count' })
	.all();
	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const searchMarketplaces = async (status, page, searchStr) => {
	const res = await MarketplaceModel
	.where({
		status: status,
		name: {match: searchStr},
	})
	.page(page)
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const filteredMarketplaces = async (status, page, tagId) => {
	const res = await MarketplaceModel
	.where({
		status: status,
		tagId: tagId,
	})
	.page(page)
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getUserMarketplaces = async (userId) => {
	const res = await MarketplaceModel.includes(['owner'])
	.where({ownerId: userId})
	.extraParams({ paginate: false})
	.all();
	return res.data;
};

const getMarketplace = async (marketPlaceId) => {
	const res = await MarketplaceModel.includes(['city', 'owner', { marketplaceTags: 'tag' }, {products: ['category']}])
	.find(marketPlaceId);
	return res.data;
};

export {
	getAllMarketplaces,
	getMarketplaces,
	searchMarketplaces,
	getUserMarketplaces,
	getMarketplace,
	filteredMarketplaces
}