import {attr} from "spraypaint";
import isEqual from 'lodash.isequal';
import ApplicationRecord from "./baseModel";

const dirtyChecker = (prior, current) => !(isEqual(prior, current))

const ServiceConfigurationsModel = ApplicationRecord.extend({
	static: { jsonapiType: "service_configurations" },
	attrs: {
		id: attr(),
		legalEntityFile: attr(),
		legalEntityFileUrl: attr(),
		bonusPoints: attr({dirtyChecker: dirtyChecker}),
		paymentProcessorAgreementFile: attr(),
		paymentProcessorAgreementFileUrl: attr(),
		privacyPolicyFile: attr(),
		privacyPolicyFileUrl: attr(),
		serviceFee: attr(),
		applicationVersion: attr(),
		termsOfUseFile: attr(),
		termsOfUseFileUrl: attr(),
		appConfig: attr({dirtyChecker: dirtyChecker}),
		addTagToRestaurantsLayout: attr(),
		removeBannerFromLayout: attr(),
		addTagToStoresLayout: attr(),
		addBannerToMainLayout: attr(),
		addBannerToRestaurantsLayout: attr(),
		addBannerToStoresLayout: attr(),
		freeDeliveryPromo: attr({dirtyChecker: dirtyChecker}),
	}
})

export default ServiceConfigurationsModel