import { v4 as uuidv4 } from 'uuid';
import MessageItem from "../messageItem/messageItem";
import {Button, Form, InputGroup} from "react-bootstrap";
import {getAdminChat} from '../../../api/adminChatsAPI';
import './activeChat.scss';
import {useEffect, useState} from "react";
import {USER} from "../../../helpers/constants/localStorageConstants";
import ChatMessageModel from "../../../models/chatMessageModel";

const ActiveChat = ({
	activeChatId,
	selectedChat,
	cable
}) => {
	let chatConnection;
	const currentUser = JSON.parse(localStorage.getItem(USER));
	const [messages, setMessages] = useState([]);
	const [currentMessageText, setCurrentMessageText] = useState('');

	const getHistory = async () => {
		await getAdminChat(activeChatId)
		.then(res => {
			setMessages(res?.chatMessages.reverse());
			scrollHandler();
		})
	};

	const sendMessageHandler = async () => {
		const currentMessage = new ChatMessageModel();
		currentMessage.content = currentMessageText;
		currentMessage.adminUserChatRoom = selectedChat;

		await currentMessage.save({with: 'adminUserChatRoom.id'})
		.then(() => {
			scrollHandler();
			setCurrentMessageText('');
		})
	};

	useEffect(() => {
		if (activeChatId) {
			getHistory();
		}
	}, [activeChatId])

	useEffect(() => {
		if (cable && cable.subscriptions && !chatConnection) {
			chatConnection = cable.subscriptions.create(
				{channel: 'AdminChatChannel', chat_room_id: activeChatId},
				{received: data => {
						setMessages(p => ([...p, {
							id: uuidv4(),
							content: data.content,
							createdAt: new Date(data.created_at),
							user: {
								id: data.user.id
							}
						}]))
						scrollHandler();
				}}
			)
		}
	},[]);

	useEffect(() => {
		const listener = (e) => {
			console.log(e.key);
			if ((e.key === "Enter") && (currentMessageText.length > 0)) {
				sendMessageHandler();
			}
		}

		document.addEventListener('keypress', listener);

		return (() => {
			document.removeEventListener('keypress', listener);
		})
	})

	const scrollHandler = () => {
		const cnt = document.getElementById('msgs');
		cnt.scrollBy({left: 0, top: 100000, behavior:"smooth"});
	}

	return (
		<>
			<div className="activeChatRootMessages" id="msgs">
				{messages.map(msg => (
					<MessageItem
						key={`${msg.id}_${msg?.content}`}
						text={msg?.content || ''}
						date={msg?.createdAt ? new Date(msg?.createdAt) : new Date()}
						isYours={Number(msg?.user?.id) === Number(currentUser.id)}
					/>
				))}
			</div>

			<div className="activeChatRootNewMessage flex">
				<InputGroup>
					<Form.Control
						placeholder="Введите текст сообщения"
						aria-describedby="basic-addon2"
						value={currentMessageText}
						onChange={e => setCurrentMessageText(e.target.value)}
					/>
				</InputGroup>
				<Button
					disabled={currentMessageText.length === 0}
					variant="dark"
					onClick={() => sendMessageHandler()}
				>
					Отправить
				</Button>
			</div>
		</>
	)
};

export default ActiveChat
