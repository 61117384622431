import StatisticModel from "../models/statisticModel";

const getAllStats = async (dateFrom, dateTo) => {
	const res = await StatisticModel
	.extraParams({ paginate: false})
	.where({start_date: dateFrom, end_date: dateTo})
	.all();

	return res.data;
};

const getMarketStats = async (dateFrom, dateTo, marketId) => {
	const res = await StatisticModel
	.extraParams({ paginate: false})
	.where({start_date: dateFrom, end_date: dateTo, marketplace_id: marketId})
	.all();

	return res.data;
};

export {
	getAllStats,
	getMarketStats
}