import {attr} from "spraypaint";
import ApplicationRecord from "./baseModel";

const StatisticModel = ApplicationRecord.extend({
	static: { jsonapiType: "statistics" },
	attrs: {
		id: attr(),
		marketplaceId: attr(),
		totalAmountPaidForItems: attr(),
		ordersCount: attr(),
		averageAmountPaidForItems: attr(),
		startDate: attr(),
		endDate: attr(),
	}
})

export default StatisticModel