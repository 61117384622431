import {Button} from "react-bootstrap";
import {NavLink} from 'react-router-dom'
import {useNavigate} from "react-router-dom";
import './adminPageWrapper.scss'
import {TOKEN, USER, VERSION} from "../../../helpers/constants/localStorageConstants";
import {useEffect} from "react";
import {getCurrentSCModel} from "../../../api/serviceConfigAPI";

const AdminPageWrapper = ({ children, lightVersion = false }) => {
	const navigate = useNavigate();

	const logoutHandler = () => {
		localStorage.removeItem(TOKEN);
		localStorage.removeItem(USER);
		localStorage.removeItem(VERSION);
		navigate('/auth');
	};

	const getConfig = async () => {
		await getCurrentSCModel()
		.then((res) => {
			localStorage.setItem(VERSION, res?.applicationVersion || '')
		})
	};

	useEffect(() => {
		getConfig();
	}, []);

	return (
		<div className='PageWrapperRoot'>
			<div className='PageWrapperContent'>
				{!lightVersion && (
					<Button className='PageWrapperLogout' variant="dark" onClick={() => logoutHandler()}>Выйти</Button>
				)}
				{!lightVersion && (
					<div className='PageWrapperVersion'>{`Версия: ${localStorage.getItem(VERSION)}`}</div>
				)}
				{!lightVersion && (
					<div className='PageWrapperNavigation'>
						<NavLink
							to={`/admin/users`}
							className="PageWrapperNavigationLink"
						>Управление пользователями</NavLink>
						<NavLink
							to={`/admin/marketplaces`}
							className="PageWrapperNavigationLink"
						>Управление бизнесами</NavLink>
						<NavLink
							to={`/admin/prioritization`}
							className="PageWrapperNavigationLink"
						>Приоритезация площадок</NavLink>
						<NavLink
							to={`/admin/tags`}
							className="PageWrapperNavigationLink"
						>Справочник тегов</NavLink>
						<NavLink
							to={`/admin/orders`}
							className="PageWrapperNavigationLink"
						>Заказы</NavLink>
						<NavLink
							to={`/admin/notifications`}
							className="PageWrapperNavigationLink"
						>Уведомления</NavLink>
						<NavLink
							to={`/admin/shares`}
							className="PageWrapperNavigationLink"
						>Настройки системы</NavLink>
						<NavLink
							to={`/admin/couriers`}
							className="PageWrapperNavigationLink"
						>Курьеры</NavLink>
						<NavLink
							to={`/admin/banners`}
							className="PageWrapperNavigationLink"
						>Баннеры</NavLink>
						<NavLink
							to={`/admin/coupons`}
							className="PageWrapperNavigationLink"
						>Купоны</NavLink>
						<NavLink
							to={`/admin/chats`}
							className="PageWrapperNavigationLink"
						>Чаты</NavLink>
						<NavLink
							to={`/admin/faq`}
							className="PageWrapperNavigationLink"
						>Вопрос / Ответ</NavLink>
						<NavLink
							to={`/admin/statistic`}
							className="PageWrapperNavigationLink"
						>Статистика</NavLink>

					{/*	<NavLink*/}
					{/*		to={`/merchant/${marketplaceId}/statistics`}*/}
					{/*		className="PageWrapperNavigationLink"*/}
					{/*	>Статистика</NavLink>*/}
					</div>

				)}
				<div className='PageWrapperChildren'>
					{children}
				</div>
			</div>
		</div>
	)
};

export default AdminPageWrapper;