import {Button} from "react-bootstrap";
import ROUTES from "../../../helpers/constants/routes";
import {NavLink} from 'react-router-dom'
import {useNavigate, useParams} from "react-router-dom";
import {TOKEN, USER, VERSION} from "../../../helpers/constants/localStorageConstants";
import './merchantPageWrapper.scss'
import {getCurrentSCModel} from "../../../api/serviceConfigAPI";
import {useEffect} from "react";

const MerchantPageWrapper = ({ children, className, lightVersion = false }) => {
	const navigate = useNavigate();
	const { marketplaceId } = useParams();

	const logoutHandler = () => {
		localStorage.removeItem(TOKEN);
		localStorage.removeItem(USER);
		localStorage.removeItem(VERSION);
		navigate('/auth');
	};

	const getConfig = async () => {
		await getCurrentSCModel()
		.then((res) => {
			localStorage.setItem(VERSION, res?.applicationVersion || '')
		})
	};

	useEffect(() => {
		getConfig();
	}, []);

	return (
		<div className='PageWrapperRoot'>
			<div className='PageWrapperHeader marginBottom40'>
				<Button variant="dark" onClick={() => {navigate(ROUTES.merchant.main)}}>{`< К бизнесам`}</Button>
			</div>
			{!lightVersion && (
				<div className='PageWrapperVersion'>{`Версия: ${localStorage.getItem(VERSION)}`}</div>
			)}
			<div className='PageWrapperContent'>
				<Button className='PageWrapperLogout' variant="dark" onClick={() => logoutHandler()}>Выйти</Button>
				{!lightVersion && (
					<div className='PageWrapperNavigation'>
						<NavLink
							to={`/merchant/${marketplaceId}/marketplace`}
							className="PageWrapperNavigationLink"
						>Профиль пользователя</NavLink>
						<NavLink
							to={`/merchant/${marketplaceId}/products`}
							className="PageWrapperNavigationLink"
						>Управление товарами</NavLink>
						<NavLink
							to={`/merchant/${marketplaceId}/categories`}
							className="PageWrapperNavigationLink"
						>Управление категориями</NavLink>
						<NavLink
							to={`/merchant/${marketplaceId}/orders`}
							className="PageWrapperNavigationLink"
						>Заказы</NavLink>
						<NavLink
							to={`/merchant/${marketplaceId}/notifications`}
							className="PageWrapperNavigationLink"
						>Уведомления</NavLink>
						<NavLink
							to={`/merchant/${marketplaceId}/statistic`}
							className="PageWrapperNavigationLink"
						>Статистика</NavLink>
						{/*<NavLink*/}
						{/*	to={`/merchant/${marketplaceId}/statistics`}*/}
						{/*	className="PageWrapperNavigationLink"*/}
						{/*>Статистика</NavLink>*/}
						{/*<NavLink*/}
						{/*	to={`/merchant/${marketplaceId}/promotional`}*/}
						{/*	className="PageWrapperNavigationLink"*/}
						{/*>Акции</NavLink>*/}
					</div>
				)}
				<div className='PageWrapperChildren'>
					{children}
				</div>
			</div>
		</div>
	)
};

export default MerchantPageWrapper;