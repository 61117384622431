import {useEffect, useState} from "react";
import {Table, Button, InputGroup} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {getAllMarketplaces} from "../../../api/marketplaceAPI";
import './statisticPage.scss';
import DatePicker from "react-datepicker";
import {format, startOfYesterday} from 'date-fns';
import {getAllStats} from "../../../api/statisticsAPI";

const StatisticPage = () => {
	const [dateFrom, setDateFrom] = useState(new Date(startOfYesterday()));
	const [dateTo, setDateTo] = useState(new Date());
	const [stat, setStat] = useState([]);
	const [markets, setMarkets] = useState([]);

	const getStat = async () => {
		await getAllStats(
			format(dateFrom, 'yyyy-MM-dd'),
			format(dateTo, 'yyyy-MM-dd')
		)
		.then(res => {
			const filtered = res.filter(rs => markets.find(m => m.id == rs?.marketplaceId));
			setStat(filtered)
		})
	};

	const getMarkets = async () => {
		await getAllMarketplaces()
		.then(res => setMarkets(res))
	}

	useEffect(() => {
		getMarkets();
	}, []);

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="flex-space-between marginBottom20">
					<h3>Статистика</h3>
				</div>

				<div className="block flex-space-between marginBottom20">
					<InputGroup style={{ flexWrap: 'nowrap'}}>
						<InputGroup.Text>Дата начала отчёта</InputGroup.Text>
						<DatePicker
							locale="ru"
							selected={dateFrom}
							onChange={date => setDateFrom(date)}
							wrapperClassName="DatePikerWrapper"
							className="DatePikerInput"
							dateFormat="dd.MM.yyyy"
						/>
					</InputGroup>
					<InputGroup style={{ flexWrap: 'nowrap'}}>
						<InputGroup.Text>Дата окончания отчёта</InputGroup.Text>
						<DatePicker
							locale="ru"
							selected={dateTo}
							onChange={date => setDateTo(date)}
							wrapperClassName="DatePikerWrapper"
							className="DatePikerInput"
							dateFormat="dd.MM.yyyy"
						/>
					</InputGroup>
					<Button variant="dark" onClick={() => getStat()}>
						Сформировать</Button>
				</div>

				{stat.length > 0 && (
					<>
						<h3 className="marginBottom20">
							{`Статистика за период c ${format(dateFrom, 'dd.MM.yyyy')} по ${format(dateTo, 'dd.MM.yyyy')}`}</h3>
						<Table striped>
							<thead className="tableHead marginBottom10">
							<tr>
								<th><h5>Площадка</h5></th>
								<th><h5>Количество заказов</h5></th>
								<th><h5>Общая сумма заказов</h5></th>
								<th><h5>Средний чек</h5></th>
							</tr>
							</thead>
							<tbody>
							{stat.map(s => (
								<tr key={s.id} className="tableRow">
									<td>{markets.find(m => m.id == s?.marketplaceId)?.name || '-'}</td>
									<td>{s?.ordersCount || '-'}</td>
									<td>{s?.totalAmountPaidForItems / 100 || '-'}</td>
									<td>{s?.averageAmountPaidForItems / 100 || '-'}</td>
								</tr>
							))}
							</tbody>
						</Table>
					</>
				)}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default StatisticPage;